import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Layout from './Components/Layout';
import Store from './Stores/Store';
import AuthProvider from './AuthProvider';
import AuthStore from './Stores/AuthStore';


new AuthProvider((accessToken) => {

    if(accessToken) {

        let apiUrl = window.location.href.indexOf("localhost") > 0 ? "http://localhost:7071" : "https://365automate-documents.azurewebsites.net";

        apiUrl = "https://365automate-documents.azurewebsites.net";

        var authStore = new AuthStore(apiUrl, accessToken); 
        
        var store = new Store(authStore);
        ReactDOM.render(<Layout store={store}></Layout>, document.getElementById('root'));
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
