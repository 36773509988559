import { UserAgentApplication } from 'msal';

  // Add here scopes for id token to be used at MS Identity Platform endpoints.
  const loginRequest = {
    scopes: ["openid", "profile", "User.Read", "sites.manage.all"]
  };


export default class AuthProvider {

    myMSALObj = new UserAgentApplication({
        auth : {
            clientId: "a6e166a3-063f-4b8d-aebc-3af986a1bf2b",
            //authority: "https://login.microsoftonline.com/organizations",
            redirectUri: window.location.origin,
        },
        cache: {
            cacheLocation: "sessionStorage", // This configures where your cache will be stored
            storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
          }
    });

    callback: (token:string) => void;

    constructor(callback:(token:string) => void){
        this.callback = callback;
        this.myMSALObj.handleRedirectCallback(this.authRedirectCallBack.bind(this));

        var account = this.myMSALObj.getAccount();
        if(!account){
            this.myMSALObj.loginRedirect();
        }

        this.myMSALObj.acquireTokenSilent({
            scopes:loginRequest.scopes
        })
        .then((response) => {
            console.log(response);
            if (response.accessToken) {
                console.log('access_token acquired at: ' + new Date().toString());
                this.callback(response.accessToken);
            }
        })
        .catch(error => {
            console.log("silent token acquisition fails. acquiring token using redirect");
            // fallback to interaction when silent call fails
            return this.myMSALObj.acquireTokenRedirect({
                scopes:loginRequest.scopes
            })
        });
    }

    authRedirectCallBack(error:any, response:any) {
        if (error) {
            console.log(error);
        } else {
            if (response.tokenType === "id_token") {
                console.log('id_token acquired at: ' + new Date().toString());        
            } else if (response.tokenType === "access_token") {
                console.log('access_token acquired at: ' + new Date().toString());
                this.callback(response.accessToken);
            } else {
                console.log("token type is:" + response.tokenType);
            }
        }
    }
};