import React, { Component } from 'react';
import {Stack, IStackItemStyles, IStackTokens} from 'office-ui-fabric-react/lib/Stack';
import { observer } from 'mobx-react'
import Store from '../Stores/Store';
import { Nav, INavLink } from 'office-ui-fabric-react/lib/Nav';
import Home from './Pages/Home';
import Generate from './Pages/Generate';
import Templates from './Pages/Templates';
import Sources from './Pages/Sources';

type LayoutProps = {
  store: Store;
}

const headerStyles: IStackItemStyles = {
  root: {
    textAlign: 'left',
    color: '#D9D9D9',
    fontSize: 20
  }
};

const navBarStyles: IStackItemStyles = {
  root: {
    height: 50,
    marginBottom:20,
    backgroundColor:'#125C22'
  }
};

const mainPageStyles: IStackItemStyles = {
  root: {
    width:'100%',
    minHeight: '100%',
    paddingLeft:10
  }
};

const themedSmallStackTokens: IStackTokens = {
  childrenGap: 's1',
  padding: 's1'
};

@observer
export default class Layout extends Component<LayoutProps, {}> {
  
  _onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
    this.props.store.page = item?.key as string;
  }

  _getPage(){
    switch(this.props.store.page){
      case "home": return <Home store={this.props.store}></Home>;
      case "generate": return <Generate store={this.props.store}></Generate>;
      case "templates": return <Templates store={this.props.store.config}></Templates>;
      case "sources": return <Sources store={this.props.store.config}></Sources>;
      
      default:
        return <div>Invalid page <b>{this.props.store.page}</b>!</div>
    }
  }

  render() {

    return <Stack>
      <Stack.Item align="auto">
        <Stack horizontal tokens={themedSmallStackTokens} styles={navBarStyles}>
          <Stack.Item grow styles={headerStyles}>
            365Automate DocGen
          </Stack.Item>
          <Stack.Item>
            {/*<Persona {...examplePersona} hidePersonaDetails presence={PersonaPresence.none} imageAlt="Annie Lindqvist, status is online." />*/}
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal>
          <Stack.Item>
          {this._getNav()}
        </Stack.Item>
        <Stack.Item styles={mainPageStyles}>
          {this._getPage()}
        </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>;
  
  }
  _getNav(): React.ReactNode {

    return <Nav
      onLinkClick={this._onLinkClick.bind(this)}
      selectedKey={this.props.store.page}
      ariaLabel="Nav basic example"
      styles={{
        root: {
          width: 208,
          height: '100%',
          boxSizing: 'border-box',
          overflowY: 'auto'
        },
      }}
      groups={[
        {
          links: [
            {
              name: 'Home',
              key: 'home',
              url: '#home',
              icon: 'Home'
            },
            {
              name: 'Source Lists',
              key: 'sources',
              url: '#sources',
              icon: 'SharepointLogo'
            },
            {
              name: 'Document Templates',
              key: 'templates',
              url: '#templates',
              icon: 'FileTemplate'
            },
            {
              name: 'Generate',
              key: 'generate',
              url: '#generate',
              icon: 'FileSymlink'
            },
          ],
        },
      ]}
    />;
  }
}