import { ConfigStore } from "../../Stores/Store";
import React, { Component } from 'react';
import { observer } from "mobx-react";
import { Stack, IStackTokens } from "office-ui-fabric-react/lib/Stack";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { MessageBar, MessageBarType } from "office-ui-fabric-react/lib/MessageBar";

type GenerateProps = {
    store: ConfigStore;
}

const stackTokens: IStackTokens = { childrenGap: 10 };

@observer
export default class Sources extends Component<GenerateProps, {}> {
    
    render(){
        
        return <Stack tokens={stackTokens}>
                <MessageBar messageBarType={MessageBarType.info}>
                    Enter the URL to a SharePoint List which contains metadata to be used in document property replacement.
                </MessageBar>
                <TextField label="Source List URL" 
                           value={this.props.store.sourceList} 
                           onChange={(_, v) => { this.props.store.pendingChange = true; this.props.store.sourceList = v as string}} />
                
                <PrimaryButton text="Save" disabled={!this.props.store.pendingChange} onClick={() => {this.props.store.save(); return false;}}></PrimaryButton>
            </Stack>
    }
}