import {observable} from 'mobx'

export default class AuthStore {

    @observable
    accessToken:string = "";

    @observable
    error:string|null = null;
    
    @observable
    graphProfile:any = null;

    private apiUrl:string;

    constructor(apiUrl:string, token:string){
        this.apiUrl = apiUrl;
        this.accessToken = token;
    }

    public fetchFile(endpoint:string, init?:RequestInit|undefined) : Promise<any> {
        return this.fetch(endpoint, init)
        .then( res => res.blob() )
        .then( blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "filename.docx";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
        });
    }


    public fetchJson(endpoint:string, init?:RequestInit|undefined) : Promise<any> {
        return this.fetch(endpoint, init)
            .then((response) => {
                return response.json();
            });
    }

    private fetch(endpoint:string, init?:RequestInit|undefined) : Promise<any> {

        if(!init){
            init = {};
        }

        if(!init.method) init.method = 'get';

        var headers = {
            'accept': 'application/json',
            'content-type': 'application/json',
            'authorization': 'Bearer '+ this.accessToken
        };

        init.headers = headers;

        return fetch(this.apiUrl + endpoint, init);
    }
}
