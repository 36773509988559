import Store from "../../Stores/Store";
import React, { Component } from 'react';
import { observer } from "mobx-react";
import { Stack, IStackTokens } from "office-ui-fabric-react/lib/Stack";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { SpinButton } from "office-ui-fabric-react/lib/SpinButton";
import { Dropdown, IDropdownOption } from "office-ui-fabric-react/lib/Dropdown";

type GenerateProps = {
    store: Store;
}

const stackTokens: IStackTokens = { childrenGap: 10 };

@observer
export default class Generate extends Component<GenerateProps, {}> {
    
    render(){
        
        const options: IDropdownOption[] = this.props.store.docGenStore.templates.map(t => {
            return {
                key: t.url,
                text: t.name
            }
        });


        return <Stack tokens={stackTokens}>
                
                <Dropdown
                    placeholder="Select template..."
                    label="Select a document template"
                    options={options}
                    selectedKey={this.props.store.docGenStore.templateUrl}
                    onChange={(_, v) => this.props.store.docGenStore.templateUrl = v?.key as string}
                />

                <SpinButton
                    defaultValue={this.props.store.docGenStore.itemId.toString()}
                    onIncrement={(v) => {this.props.store.docGenStore.itemId = parseInt(v)}}
                    onDecrement={(v) => {this.props.store.docGenStore.itemId = parseInt(v)}}
                    label={'Item ID'}
                    min={1}
                    step={1}
                    iconProps={{ iconName: 'IncreaseIndentLegacy' }}
                    />

                <PrimaryButton text="Generate" onClick={() => {this.props.store.docGenStore.generate(); return false;}}></PrimaryButton>
            </Stack>
    }
}