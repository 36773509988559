import Store from "../../Stores/Store";
import React, { Component } from 'react';
import { observer } from "mobx-react";

type HomeProps = {
    store: Store;
  }

@observer
export default class Home extends Component<HomeProps, {}> {
    
    render(){

        return <div>
                <HomeSection wide={true}>
                    workflows
                </HomeSection>
                <HomeSection wide={false}>
                    argh2
                </HomeSection>
                <HomeSection wide={true}>
                    argh3
                </HomeSection>
            </div>
    }
}

interface IHomeSectionProps {
    wide: boolean;
}

class HomeSection extends Component<IHomeSectionProps,{}>{
    render(){
        return <div style={{ marginBottom:10, marginRight:10, display:'inline-block', width:(this.props.wide ? '57%' : '37%'), border:'1px solid #EDEBE9' }}>
            {this.props.children}
        </div>;
    }
}