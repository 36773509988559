import {observable, action} from 'mobx'
import AuthStore from './AuthStore';

export class Store {

    @observable
    page:string = "home";

    authStore:AuthStore;

    @observable
    config:ConfigStore;

    docGenStore: GenerateDocumentStore;

    constructor(authStore:AuthStore){
        this.authStore = authStore;
        this.config = new ConfigStore(this.authStore);
        this.docGenStore = new GenerateDocumentStore(this.authStore, this.config);
        this.config.load();

        //this.app = (window.location.pathname || "")

        var pageHash:string = window.location.hash.slice(1);
        if(pageHash){
            this.page = pageHash;
        }
    }
}

export class ConfigStore {

    authStore:AuthStore;

    @observable
    public pendingChange: boolean = false;

    @observable
    public templatesDocLib: string = "";

    @observable
    public sourceList: string = "";

    constructor(authStore:AuthStore){
        this.authStore = authStore;
    }

    @action
    public load() {
        this.authStore.fetchJson('/api/GetConfig', {
            method: 'get'
        }).then(config => {
            this.templatesDocLib = config.templatesDocLib;
            this.sourceList = config.sourceList;
        });
    }

    @action 
    public save(): void {
        this.pendingChange = false;
        this.authStore.fetchJson('/api/SetConfig', {
            method:'post',
            body: JSON.stringify({
                templatesDocLib: this.templatesDocLib,
                sourceList: this.sourceList
            })
        });
    }


}

class Template {
    public name:string;
    public url:string;

    constructor(obj:any) {
        this.name = obj.name;
        this.url = obj.url;
    }
}

class GenerateDocumentStore {

    auth:AuthStore;
    config:ConfigStore

    constructor(authStore:AuthStore, config:ConfigStore) {
        this.auth = authStore;
        this.config = config;
        this.generate = this.generate.bind(this);
        this.loadTemplates = this.loadTemplates.bind(this);

        this.loadTemplates();
    }
    
    @observable
    public templateUrl:string = "";
    
    @observable
    public itemId:number = 1;

    @observable
    public templates:Template[] = [];

    @action
    loadTemplates():void {
        this.auth.fetchJson('/api/GetTemplates').then(templates => {
            this.templates = templates.map((t: any) => new Template(t));
        });
    }

    @action
    generate(): void {
        let body = JSON.stringify({
            templateUrl: this.templateUrl,
            itemId: this.itemId,
            listUrl:this.config.sourceList
        });

        this.auth.fetchFile('/api/Generate', {
            method: 'post',
            body: body 
        });
    }
}

export default Store;
